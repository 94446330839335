<template>
  <div>
    <el-container>
      <el-main>
        <div class="public-margin-bottom flex-right">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="onAddDepartment"
          >
            新增部门
          </el-button>

          <el-button
            v-show="authType == 'H'"
            type="success"
            size="mini"
            @click="onShowSync"
            >同步部门</el-button
          >
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          height="calc(100vh - 148px)"
          stripe
        >
          <el-table-column width="48">
            <template slot="header" slot-scope="scope">
              <el-checkbox
                v-model="isActiveCheck"
                :checked="allCheck"
                :indeterminate="indeterminate"
                @change="onSelectChange('all', tableData)"
              ></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox
                :value="scope.row.isCheck"
                :checked="scope.row.isCheck"
                :indeterminate="false"
                @change="onSelectChange('', scope.row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="DeptNo" label="部门编号"> </el-table-column>
          <el-table-column prop="DeptName" label="部门名称"> </el-table-column>
          <el-table-column label="日结短信">
            <template slot-scope="scope">
              <div>{{ scope.row.DayEndSms | whether }}</div>
            </template>
          </el-table-column>
          <el-table-column label="是属总部">
            <template slot-scope="scope">
              <div>{{ scope.row.HeadMark | whether }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="onEditDepartment(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="onDelectDepartment(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <!-- end el-main -->
    </el-container>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="dialogTableVisible"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>

    <!-- 同步至门店对话框 -->
    <DfSyncStoreDialog
      ref="DfSyncStoreDialog"
      :syncFuction="synDepartment"
    ></DfSyncStoreDialog>
  </div>
</template>

<script>
import employee from "../../../api/employee.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  components: { EditDialog },
  data() {
    return {
      authType: "",
      indexAction: 0,
      tableData: [],
      dialogTableVisible: false,
      etitle: "",
      formData: {},

      requestPowerParam: {
        // 控制权限的对话框
        show: false,
        key: "",
        code: "",
        power: "",
        callback: () => {},
      },
      selectItems: [],
      isActiveCheck: false,
      allCheck: false,
      indeterminate: false,
    };
  },
  mounted() {
	let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    // 获取数据
    async initPageData(itemGuids = []) {
      this.tableData = [];
      this.isActiveCheck = false;
      this.allCheck = false;
      this.indeterminate = false;

      try {
        let { data, errcode, errmsg } = await employee.getDepartment({});
        if (errcode == 0) {
          // if (itemGuids.length > 0) {
          //   this.isActiveCheck = true;
          //   this.onSelectChange("all", data);
          //   return;
          // } else if (this.selectItems.length > 0) {
          //   this.tableData = data;
          //   this.onRowSelection();
          //   return;
          // }
          this.tableData = data;
        } else {
          this.$message.error(errmsg);
        }
      } catch (e) {}
    },

    // 删除数据
    onDelectDepartment(event) {
      this.$confirm(`是否删除该‘${event.DeptName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await employee.deleteDepartment({
              dept_guid: event.DeptGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

	// 同步
    async synDepartment(event, callback) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems));
	  
      try {
        let res = await employee.synDepartment(event.authKey, event.authCode, {
          SourceStoresGuid: window.sessionStorage.getItem("selectStoreGuid"),
          TargetStoresGuid: event.targetStoresGuid,
          SelectDeptGuids: selectItems.map((e) => e.DeptGuid),
        });
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    },

	// 多选框改变事件
    async onSelectChange(event = "", row) {
      let selectItems = JSON.parse(JSON.stringify(this.selectItems)),
        tableData = JSON.parse(JSON.stringify(row));
      this.loading = true;
      if (event == "all") {
        selectItems = [];
        await tableData.forEach((e) => {
          if (this.isActiveCheck) {
            e.isCheck = true;
            selectItems.push(e);
          } else {
            selectItems = [];
            e.isCheck = false;
          }
        });
        this.tableData = await tableData;
      } else {
        let index = await selectItems.findIndex(
          (e) => e.DeptGuid == row.DeptGuid
        );
        index > -1
          ? selectItems.splice(index, 1)
          : (selectItems = [...selectItems, row]);
        row.isCheck = !row.isCheck;
      }

      this.selectItems = await selectItems;
      await this.onAllSelection();
      await setTimeout(() => {
        this.loading = false;
      }, 200);
      // this.selectItems = [...this.selectItems, row]
      // console.log(this.isActiveCheck, this.allCheck, this.indeterminate);
    },

    // 多选回显
    onRowSelection() {
      this.$nextTick(async () => {
        let count = 0;
        // console.log(this.tableData, this.selectItems);
        await this.selectItems.forEach((item) => {
          for (let index = 0; index < this.tableData.length; index++) {
            if (this.tableData[index].DeptGuid === item.DeptGuid) {
              count++;
              // console.log(this.tableData[index], item);
              // this.$refs.multipleTable.toggleRowSelection(
              //   this.tableData[index],
              //   true
              // );
              this.tableData[index].isCheck = true;
              break;
            }
          }
        });

        if (count > 0) {
          await this.onAllSelection();
        } else {
          this.isActiveCheck = false;
          this.allCheck = false;
          this.indeterminate = false;
        }
      });
    },

    // 全选回显
    async onAllSelection() {
      this.isActiveCheck = (await this.selectItems.length) > 0 ? true : false;
      this.allCheck =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length == this.tableData.length
          ? true
          : false;
      this.indeterminate =
        (await this.selectItems.length) > 0 &&
        this.selectItems.length != this.tableData.length
          ? true
          : false;
    },

    onEditDepartment(event) {
      this.etitle = "编辑部门";
      this.formData = event;
      this.dialogTableVisible = true;
    },

	// 打开同步对话框
    onShowSync() {
      this.util.checkPower("1007", this.requestPowerParam, async () => {
        let arr = await this.selectItems.map((e) => e.DeptGuid);
        if (!arr.length) {
          return this.$message.error("请先选择部门");
        }
        this.$refs.DfSyncStoreDialog.onShowDialog(arr);
      });
    },

    // 添加数据
    onAddDepartment(event) {
      this.etitle = "添加部门";
      this.formData = {
        DeptName: "",
        DayEndSms: "",
      };
      this.dialogTableVisible = true;
    },

    //
    callFatherFunction() {
      this.dialogTableVisible = false;
    },

    onCloseDialog() {
      this.initPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  .el-aside {
    height: calc(100vh - 100px);
    border-right: 1px solid #d3dce6;
    .jobs {
      height: 40px;
      line-height: 40px;
      padding-left: 15px;
      color: #606266;
      margin-bottom: 10px;
    }
    .position {
      height: 36px;
      line-height: 36px;
      padding-left: 15px;
      cursor: pointer;
    }
    .action {
      background-color: #f5f7fa;
    }
  }
  .el-main {
    position: relative;
    padding: 0;
  }
}
</style>
